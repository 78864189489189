import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Footer/Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (
        <footer className='footer bg-dark text-white py-6' id='footer'>
            <div className='container'>
                <div className='footer-content text-center'>
                    <div className='foot-last-text' style={{ display: 'flex', justifyContent: 'space-between', fontSize: '15px' }}>
                        <p onClick={() => handleNavigate('/terms-and-conditions')}>Terms and Conditions </p>
                        <p onClick={() => handleNavigate('/privacypolicy')}>Privacy Policy</p>
                        <p onClick={() => handleNavigate('/returnandreplacement')}>Return And Replacement</p>
                    </div>
                    <div className='footer-social-icons flex flex-center'>
                        <a href="https://www.facebook.com/profile.php?id=61563909312542" target="_blank" rel="noopener noreferrer" className='bg-red flex flex-center'>
                            <FaFacebookF />
                        </a>
                        <a href="https://www.instagram.com/vivent.admn1/" target="_blank" rel="noopener noreferrer" className='bg-red flex flex-center'>
                            <FaInstagram />
                        </a>
                    </div>
                    <div className='footer-bottom-text'>
                        <p className='text fs-16'>copyright &copy; 2024 GeekProbin. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
