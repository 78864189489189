import partyimageone from '../assets/images/party-img-1_new.jpg'
import partyimagetwo from '../assets/images/view-futuristic-concert_new.jpg'
import aboutus1 from '../assets/images/beautiful-christmas-interior-decoration_new.jpg'
import aboutus2 from '../assets/images/event-hall-with-white-purple-balloons_new.jpg'
import support1 from '../assets/images/table-arrangement-birthday-event-with-cake-glass-champagne_new.jpg'
import venue1 from '../assets/images/venue1_new.jpg'
import catering1 from '../assets/images/catering1_new.jpg'
import decor1 from '../assets/images/decor1_new.jpg'
import Entertainment1 from '../assets/images/entertainment1_new.jpg'
import activities1 from '../assets/images/wedding activities1_new.jpg'
import support3 from '../assets/images/how-is-rich-people-have-supper-prepared-desk-waiting-food-visitors-evening-time_new.jpg'
import contactus1 from '../assets/images/Call center 1.gif'
import bannernew4 from '../assets/images/banner-new-4_new.jpg'
import newchat from '../assets/images/chat_new.jpg'
import cakelive from '../assets/images/cakelive_new.jpg'
import giftamico from '../assets/images/Gift-amico_new.png'
import banner from '../assets/images/antique-gold-flower-light-candle_new.jpg'
import banner2 from '../assets/images/just-married-dancing_new.jpg'

// import venue1 from '../assets/images/venue1.jpg'


export default {
    partyimageone, partyimagetwo, aboutus1, aboutus2, support1, venue1, catering1, decor1, Entertainment1, activities1, support3, contactus1, bannernew4, newchat, cakelive, giftamico, banner, banner2
}