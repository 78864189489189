import { type } from "@testing-library/user-event/dist/type";
import images from "./images";

const what_we_do = [
    {
        image: `${images.aboutus1}`,
        title: "Vinpact Digital Strategies: Where Innovation Meets Celebration",
        paragraph: "At Vinpact Digital Strategies, we are not just a mobile app development company – we are a team of passionate technologists and strategists fuelled by a shared vision: to create groundbreaking apps that revolutionize industries. We have spent the past over a decade honing our craft, accumulating a wealth of experience across all aspects of technology and business management. This expertise allows us to not only build cutting-edge apps, but also understand the strategic needs that drive them. Our dedication to innovation is evident in Vivent, our flagship app. Vivent is not just anothergifting platform – it is a game-changer for the celebrations industry. Born from our desire to make celebrating easier, more meaningful, and truly unforgettable, Vivent promises to transform how you connect with loved ones on those special occasions. But Vivent is just the beginning. At Vinpact, we are constantly brainstorming, developing, and pushing the boundaries of what mobile apps can achieve. We believe in the power of technology to simplify lives, strengthen connections, and create lasting memories."
    },
    {
        image: `${images.aboutus2}`,
        title: "Vivent: Reimagining Celebrations with Every Tap",
        paragraph: "At Vinpact Digital Strategies, where a passionate team united by a single purpose: Vivent. Vivent is not just a mobile app – it is a revolution in the way we celebrate. For over 15 years, we have honed our expertise in technology and management.This experience fuels our relentless pursuit of innovation, and Vivent is the culmination of that pursuit. We saw a celebrations industry ripe for change, and Vivent is our answer. Vivent is not another gifting platform. It is a comprehensive solution designed to make celebrating easier, more meaningful, and truly unforgettable. With Vivent, you can:  Effortlessly connect with loved ones for any occasion.  Craft personalized experiences that go beyond just a gift.  Share memories and create lasting moments of joy. We understand that every celebration is unique. Vivent empowers you to personalize every aspect, from choosing the perfect gift to adding a heartfelt message. It is about fostering deeper connections and creating memories that last a lifetime.The Future of Celebrations Starts Here Vivent is just the beginning. We are constantly evolving and innovating to make celebrations even more special. With Vinpact, you are not just getting an app – you are joining a community passionate about making every occasion extraordinary. Vision : Vivent envisions to be the leader in every vertical where celebrations are involved Mission: Vivent strives to be the one stop solution for virtual celebrations, online gifting, and event services"
    }
]

const features = [
    {
        icon: `${images.venue1}`,
        title: "Venue selection",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        icon: `${images.catering1}`,
        title: "Catering and menu planning",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        icon: `${images.decor1}`,
        title: "Decor and design",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        image: `${images.Photography1}`,
        title: "Photography and videography",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        icon: `${images.Entertainment1}`,
        title: "Entertainment coordination",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        icon: `${images.Transport1}`,
        title: "Transport and accommodation",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        icon: `${images.activities1}`,
        title: "Pre- and post-wedding activities",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        image: `${images.partyimagetwo}`,
        title: "Customized ceremonies",
        paragraph: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
]

const packages = [
    {
        type: "basics",
        service_list: [
            '5 web sites',
            '15 emails',
            '5 mysql databases',
            '10 gb storage'
        ],
        price: '5'
    },
    {
        type: "standard",
        service_list: [
            '5 web sites',
            '15 emails',
            '5 mysql databases',
            '20 gb storage'
        ],
        price: '12'
    },
    {
        type: "premium",
        service_list: [
            '5 web sites',
            '15 emails',
            '5 mysql databases',
            '50 gb storage'
        ],
        price: '19'
    },
]

const projects = [
    {
        img: `${images.partyimageone}`,
        title: 'something'
    },
    {
        img: `${images.partyimagetwo}`,
        title: 'something'
    },
    {
        img: `${images.partyimageone}`,
        title: 'something'
    },
    {
        img: `${images.partyimagetwo}`,
        title: 'something'
    },
    {
        img: `${images.partyimageone}`,
        title: 'something'
    },
    {
        img: `${images.partyimagetwo}`,
        title: 'something'
    },
    {
        img: `${images.partyimageone}`,
        title: 'something'
    },
    {
        img: `${images.partyimagetwo}`,
        title: 'something'
    }
]

const teams = [
    {
        img: `${images.partyimageone}`,
        name: "syed",
        post: 'developer'
    },
    {
        img: `${images.partyimagetwo}`,
        name: "penny",
        post: 'developer'
    },
    {
        img: `${images.partyimageone}`,
        name: "lennerd",
        post: 'developer'
    },
    {
        img: `${images.partyimagetwo}`,
        name: "sheldon",
        post: 'developer'
    },
    {
        img: `${images.partyimageone}`,
        name: "ashley",
        post: 'developer'
    },
]

const support = [
    {
        img: `${images.contactus1}`
    }
]

const OurServices = [
    {
        img: `${images.newchat}`
    },
    {
        img: `${images.cakelive}`
    }
    
]

export default {
    what_we_do, features, packages, projects, teams, OurServices
}