import React from 'react'
import './OurServices.css'
import images from '../../constants/images';
import data from '../../constants/data';

const OurServices = () => {
    return (
        <section className='our-services' id='our-services'>
            <div className="main-service">
                <div className='section-title bg-dark'>
                    <h2 className='text-upper text-white text-center'>Our Services</h2>
                </div>
                <div className='main-second'>
                    <div className='inside-one-main'>
                        <h4>Create and share invitations</h4>
                        <img style={{ height: '300px', width: '300px' }} src={images.newchat} alt='no img'></img>
                    </div>
                    <div className='inside-two-main'>
                        <h4>Live telecast your celebration</h4>
                        <img style={{ height: '300px', width: '300px' }} src={images.cakelive} alt='no img'></img>
                    </div>
                    <div className='inside-three-main'>
                        <h4>Gift your dear ones on the fly</h4>
                        <img style={{ height: '300px', width: '300px' }} src={images.giftamico} alt='no img'></img>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices
