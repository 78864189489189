import React, { useState } from 'react';
import "../Support/Support.css";
import images from '../../constants/images';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { LiaLinkedin } from 'react-icons/lia';

const Support = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
        // Optionally, you can close the modal after submission
        setShowModal(false);
    };

    return (
        <section className='support py-6' id='support'>
            <div className='support-main'>
                <div className='container'>
                    <div className='section-title bg-dark'>
                        <h2 className='text-upper text-white text-center'>CONTACT US</h2>
                    </div>
                    <div className='sub-container'>
                        <div className='item'>
                            <div className='contact'>
                                <div className='first-text'>Let's get in touch</div>
                                <img src={images.contactus1} alt='something' className='image'></img>
                                <div className='social-links'>
                                    <span className='second-text'>Contact Us:</span>
                                    <p style={{ fontFamily: 'initial', color: 'gray' }} className='address-para-one'>
                                        H NO 21-102/A, Vasathi, Peeramcheruvu, Gandipet, Hydershahkote, Rajendra Nagar, Hyderabad-500091, Telangana, India
                                    </p>
                                    <p style={{ fontFamily: 'initial', color: 'gray' }}>Phone: +91 7993947342, Email: info@vivent.co.in</p>
                                    <span className='second-text'>Connect with us:</span>
                                    <ul className='social-media'>
                                        <li><a href="https://www.facebook.com/profile.php?id=61563909312542" target="_blank" rel="noopener noreferrer"><FaFacebook className='social-i' /></a></li>
                                        <li><a href="https://www.instagram.com/vivent.admn1/" target="_blank" rel="noopener noreferrer"><FaInstagram className='social-i' /></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Support;
