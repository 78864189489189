import React from 'react';

const ReturnAndReplacement = () => {
    return (
        <div className="terms-container">
            <h1>Returns, Refunds, Replacements, and Cancellations Policy</h1>
            <p>Welcome to VIVENT! We curate a delightful gifting experience for both the giver and receiver. This comprehensive policy outlines your options for returning, receiving a refund, replacing, or cancelling a gift purchased through our app.</p>
            <h2>1. Your Rights Under Indian Law:</h2>
            <p>The Consumer Protection Act, 2019 (India) empowers you with certain rights regarding online purchases. This policy builds upon those rights while considering the unique nature of gifting.</p>
            <h2>2. Return & Exchange Eligibility:</h2>
            <p>You may return or exchange a gift purchased on our app within 7 days of delivery, subject to the following conditions:</p>
            <ul>
                <li><strong>Original Condition:</strong> The item must be unused, unwashed, and in its original packaging with all tags and labels attached.</li>
                <li><strong>Eligibility:</strong> The item must not be:
                    <ul>
                        <li>A perishable product (food, flowers, etc.)</li>
                        <li>An item marked as non-returnable on the product description page.</li>
                        <li>A digital gift voucher/certificate (unless there's an issue with code activation).</li>
                    </ul>
                </li>
                <li><strong>Proof of Purchase:</strong> You must possess a valid proof of purchase, such as your order confirmation email or receipt.</li>
            </ul>
            <h2>3. Reasons for Return & Exchange:</h2>
            <p>We accept returns and exchanges for the following reasons:</p>
            <ul>
                <li><strong>Incorrect Item Received:</strong> If you receive an item different from what you ordered, please contact us immediately. We will arrange for the correct item to be shipped to you at no additional cost.</li>
                <li><strong>Damage or Defective Item:</strong> If the item arrives damaged or defective, please contact us within 7 days of delivery. We will offer you a full refund or a replacement item, whichever you prefer.</li>
                <li><strong>Change of Mind:</strong> If you change your mind about a gift, you may return it for a full refund within 7 days of delivery. However, you will be responsible for the return shipping costs in this case.</li>
            </ul>
            <h2>4. Exceptions and Limitations:</h2>
            <p>Please note the following exceptions and limitations:</p>
            <ul>
                <li><strong>Perishable Items:</strong> Food items, flowers, or other perishable products are not eligible for return or exchange due to their inherent nature.</li>
                <li><strong>Non-Returnable Products:</strong> Some products might be marked as non-returnable due to hygiene or safety concerns. These details will be clearly mentioned on the product description page.</li>
                <li><strong>Personalized/Customized Items:</strong> Items personalized or customized specifically for the recipient cannot be returned or exchanged unless there's a proven error on our part.</li>
            </ul>
            <h2>5. Cancellations:</h2>
            <ul>
                <li>You can cancel your order before it ships by contacting our customer support team through the app or by email at support@vivent.co.in.</li>
                <li>If the order hasn't been processed yet, we will cancel it and issue a full refund to the original payment method.</li>
                <li>Once the order has been shipped, cancellation is not possible. You would then need to follow the return process outlined above.</li>
            </ul>
            <h2>6. The Return Process:</h2>
            <ul>
                <li>To initiate a return or exchange, kindly contact our customer support team within the specified return window. You can reach us through email at support@vivent.co.in.</li>
                <li>Our support team will provide you with a Return Authorization Number (RAN) and detailed instructions on how to return the item securely.</li>
                <li>Pack the item in its original packaging with the RAN slip and any relevant documents (proof of purchase, etc.).</li>
                <li>You are responsible for arranging and covering the return shipping costs unless the item received is incorrect or damaged (in which case, we will cover the return shipping).</li>
                <li>Please retain the tracking information for your records until we confirm receipt of the returned item.</li>
            </ul>
            <h2>7. Refunds:</h2>
            <ul>
                <li>Once we receive your returned item and verify that it meets the return criteria, we will initiate a refund within 7 business days.</li>
                <li>The refund will be issued to the original payment method used for the purchase. If the product was gifted by a guest at the time of viewing the live telecast of the celebration, the refund will be processed to the original payment method of the guest.</li>
                <li>Depending on your bank's processing time, the refunded amount might take a few additional days to reflect in your account.</li>
            </ul>
            <h2>8. Replacements:</h2>
            <ul>
                <li>If you wish to replace an item due to damage, defect, or incorrect order, we will arrange for a replacement to be shipped to you at no additional cost.</li>
                <li>The replacement will be processed once we receive the returned item and verify the issue.</li>
            </ul>
            <h2>9. Communication and Dispute Resolution:</h2>
            <ul>
                <li>We encourage clear communication throughout the return, replacement, or cancellation process. Please feel free to contact our customer support team if you have any questions or require assistance.</li>
                <li>In case of any dispute regarding the returned item's condition or eligibility for return, we will strive for a fair and amicable resolution based on the documented evidence and applicable laws.</li>
            </ul>
            <h2>10. Compliance:</h2>
            <p>This Returns, Refunds, Replacements, and Cancellations Policy adheres to the following:</p>
            <ul>
                <li>The Consumer Protection Act, 2019</li>
            </ul>
        </div>
    );
}

export default ReturnAndReplacement;
