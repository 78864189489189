import React from 'react';
import data from '../../constants/data';
import "../Team/Team.css";

const Team = () => {
    return (
        <section className='team py-6 bg-red' id='team'>
            <div className='container'>
                <div className='section-title bg-dark'>
                    <h2 className='text-upper text-white text-center'>our team</h2>
                </div>
                <div className='team-content py-6 grid'>
                    {
                        data.teams.map((team, index) => {
                            return (
                                <div className='team-item text-center text-white' key={index} data-aos = "fade-up">
                                    <img src={team.img} alt='team image' className='mx-auto'></img>
                                    <p className='text-upper fw-7'>{team.name}</p>
                                    <span className='text-upper'>{team.post}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Team
