import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "../Header/Header.css";
import { FaPaperPlane } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import { RiAppleLine } from "react-icons/ri";
import { FaGooglePlay } from "react-icons/fa";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import OurServices from "../services/OurServices";
import Footer from "../Footer/Footer";
import Support from "../Support/Support";

const Header = () => {
    // State to keep track of current image index
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Array of images
    const bannerImages = [ images.banner2, images.banner, images.bannernew4];

    useEffect(() => {
        // Function to change image index every 2 seconds
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
        }, 3000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures effect runs only once on component mount

    return (
        <div>
            <header className="header flex" id="header">
                <Navbar />
                <div className="main-banner">
                    <img src={bannerImages[currentImageIndex]} alt={`Banner ${currentImageIndex + 1}`} />
                </div>
                <div className="download-btns">
                    <h1>Celebrating Life's Magical Moments with Elegance and Grace</h1>
                    
                    <button className="store-btn" data-aos="fade-right" onClick={() => window.location.href='https://play.google.com/store/apps/details?id=com.app.vivent'}>
                        <span className="btn-effect"></span>
                        <span className="icon"><p className="fa-brands"><FaGooglePlay /></p></span>
                        <span className="text-box">
                            <p className="txt-1">Free Download</p>
                            <p className="txt-2">Google Play</p>
                        </span>
                    </button>
                </div>
                <WhatWeDo />
                <OurServices />
                <Support />
            </header>
            <Footer />
        </div>
    );
}

export default Header;
