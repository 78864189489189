import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel CSS
import images from '../../constants/images';
import "./Features.css"; // Your custom CSS for Features component

const Features = () => {
    return (
        <section className='features py-6' id='features'>
            <div className='services-main'>
                <div className='container'>
                    <div className='section-title bg-dark'>
                        <h2 className='text-upper text-white text-center'>OUR SERVICES</h2>
                    </div>
                    
                    <div className="slider-container">
                        <Carousel
                            showThumbs={false} // Hide thumbnail navigation
                            infiniteLoop={true} // Infinite looping of slides
                            autoPlay={true} // Enable autoplay
                            interval={3000} // Autoplay interval in milliseconds
                            stopOnHover={false} // Continue autoplay on hover
                            transitionTime={2000} // Transition time in milliseconds
                            emulateTouch={true} // Emulate touch events for desktop
                            swipeable={true} // Enable swiping on touch devices
                            dynamicHeight={false} // Disable dynamic slide height
                            className="carousel" // Custom class for styling
                        >
                            <div>
                                <img src={images.venue1} alt="Venue" />
                            </div>
                            <div>
                                <img src={images.Photography1} alt="Photography" />
                            </div>
                            <div>
                                <img src={images.activities1} alt="Activities" />
                            </div>
                            <div>
                                <img src={images.Transport1} alt="Transport" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;
