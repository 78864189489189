import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="terms-container">
            <h1>Privacy Policy</h1>
            <p>Welcome to VIVENT!</p>
            <p>
                This Privacy Policy describes how Vinpact Digital Strategies Private Limited ("we", "us", or "our") collects, uses, and discloses your information in connection with your use of the VIVENT mobile application ("App"). We are committed to protecting your privacy and complying with all applicable Indian laws, including the Information Technology Act, 2000 and its amendments.
            </p>
            <h2>1. Information We Collect</h2>
            <p>We collect information necessary to operate the App and provide you with the services you choose to utilize. This includes:</p>
            <ul>
                <li><strong>Account Information:</strong> When you create an account, we collect your username, email address, and any other information you choose to provide (optional profile picture, etc.).</li>
                <li><strong>Contact Information:</strong> You may choose to store contact information in the App to facilitate sending invitations. This information may include names, phone numbers, and email addresses. We recommend you only store contact information for which you have permission.</li>
                <li><strong>Event Information:</strong> When you create an event through the App, we collect information about the event, such as the event name, date, time, location (if applicable), and guest list (if applicable).</li>
                <li><strong>Device Information:</strong> We collect information about the device you use to access the App, such as your device type, operating system version, and unique device identifier.</li>
                <li><strong>Usage Information:</strong> We collect information about how you use the App, such as the features you access, the invitations you view or create, and your interactions with the live telecast feature (if applicable).</li>
            </ul>
            <p>
                In addition to the information mentioned above, we will only access additional information with your explicit consent through the permission prompts within the App. This includes:
            </p>
            <ul>
                <li><strong>SMS Content:</strong> If you choose to utilize the SMS invitation feature, we will access the content of the message you compose (excluding recipient information) solely to facilitate sending the SMS on your behalf. Your message content will not be stored or used for any other purpose.</li>
                <li><strong>Audio Recordings:</strong> If you utilize the live telecast feature with audio, we will collect the audio recording of your event. This recording will be streamed live to invited guests and will not be permanently stored on our servers unless you choose to record the live event (explained further below).</li>
                <li><strong>Photos and Videos:</strong> If you utilize the live telecast feature with video or include photos/videos in your digital invitations, we will access the specific photos or videos you choose to share. These will be streamed live to invited guests or displayed in your invitation (depending on the feature used).</li>
            </ul>
            <p>We will not collect any SMS content, audio recordings, or photos/videos without your explicit consent through the permission prompts within the App.</p>
            <p>We will use the collected information solely for the purpose of enabling the specific features you choose to utilize.</p>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide and operate the App, including allowing you to create and manage events, send invitations, and participate in live telecasts.</li>
                <li>Send you notifications about your account and events you have created, and you are invited.</li>
                <li>Improve the App and develop new features.</li>
                <li>Analyze trends and user behavior to personalize your experience (optional, with user consent).</li>
                <li>Comply with legal and regulatory requirements.</li>
            </ul>

            <h2>3. Sharing Your Information</h2>
            <ul>
                <li>We may share your information with third-party service providers who help us operate the App, such as cloud storage providers, analytics providers, and email service providers. These service providers are contractually obligated to keep your information confidential and secure.</li>
                <li>We will not share your information with any third-party for marketing purposes without your consent.</li>
                <li>We may disclose your information if required to do so by law or in the good faith belief that such disclosure is necessary to comply with a court order, subpoena, or other legal process, or to protect the rights, property, or safety of us, our users, or the public.</li>
            </ul>

            <h2>4. Your Choices</h2>
            <ul>
                <li>You can access and update your account information at any time through the App settings.</li>
                <li>You can also choose to opt out of receiving marketing communications from us (if applicable).</li>
            </ul>

            <h2>5. Data Retention</h2>
            <p>We will retain your information for as long as your account is active or as needed to provide you with services. We will also retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

            <h2>6. Security</h2>
            <p>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet or electronic storage system is 100% secure. We recommend you choose a strong password for your account and exercise caution when sharing personal information through the App.</p>

            <h2>7. Children's Privacy</h2>
            <p>Our App is not directed to children under 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us. If we learn that we have collected personal information from a child under 18, we will delete that information from our systems.</p>

            <h2>8. International Transfers</h2>
            <p>Your information may be transferred to and processed in countries other than India. These countries may have different data protection laws than India. By using the App, you consent to the transfer of your information to these countries.</p>

            <h2>9. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@vivent.co.in">info@vivent.co.in</a>. You also have the right to lodge a complaint with the appropriate authority if you believe your privacy rights have been violated.</p>

            <h3>Additional Notes for Indian Users:</h3>
            <ul>
                <li>We are committed to complying with the provisions of the Information Technology Act, 2000 and its amendments.</li>
                <li>You have the right to access and correct your personal information stored with us.</li>
                <li>You can withdraw your consent to the processing of your personal information at any time.</li>
            </ul>
        </div>
    );
}

export default PrivacyPolicy;
