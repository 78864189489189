import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms & Conditions</h1>
            <p>Welcome to VIVENT!</p>
            <p>
                These Terms & Conditions ("Terms") govern your access to and use of the Vivent mobile application ("App"), developed and operated by Vinpact Digital Strategies Private Limited ("we", "us", or "our"), having its registered office located at H NO 21-102/A, Vasathi, Peeramcheruvu, Gandipet, Hydershahkote, Rajendra Nagar, Hyderabad-500091, Telangana, India. By downloading, installing, or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, please do not use the App.
            </p>
            <h2>1. Account & Use</h2>
            <p>1.1 You must be 18 years of age or older to create an account and use the App for all functionalities, including live streaming and making purchases. Users under 18 must have parental or guardian consent.</p>
            <p>1.2 You are responsible for maintaining the confidentiality of your account information, including your login credentials. You are also fully responsible for all activities that occur under your account.</p>
            <p>1.3 You agree to use the App for lawful purposes only and in accordance with these Terms. You will not:</p>
            <ul>
                <li>Violate any applicable laws or regulations, including the Information Technology Act, 2000 and its amendments.</li>
                <li>Infringe on the intellectual property rights of others.</li>
                <li>Transmit any harassing, abusive, defamatory, obscene, or threatening content.</li>
                <li>Interfere with the servers or networks connected to the App.</li>
                <li>Attempt to gain unauthorized access to the App or any related systems.</li>
                <li>Use the App for commercial purposes without our express written permission.</li>
                <li>Engage in any activity that disrupts the smooth functioning of the App or any transaction process.</li>
            </ul>
            <p>1.4 We reserve the right to terminate your account or restrict your access to the App at any time, with or without notice, for any violation of these Terms.</p>

            <h2>2. User Permissions</h2>
            <p>2.1 To enable certain features of the App and provide a more personalized experience, you may be prompted to grant access to the following permissions on your device:</p>
            <ul>
                <li>SMS: This permission grants the App the ability to compose and send text message invitations on your behalf (with your explicit consent for each message). This feature is entirely optional and can be used to notify invitees about your event in addition to the digital invitation within the App.</li>
                <li>Microphone: This permission allows you to use the microphone during the live telecast feature to capture audio of your event. This enables guests to experience the sounds of your celebration in real-time.</li>
                <li>Camera: This permission allows you to access your device's camera during the live telecast of an event.
                    <ul>
                        <li>As a Host, you can use the camera to stream live video of your event, giving guests a visual perspective of the celebration.</li>
                        <li>As a Guest, the Host can view you and other guests while giving the live telecast.</li>
                    </ul>
                </li>
            </ul>
            <p>2.2 Granting these permissions is entirely voluntary. You can choose to deny any permission without impacting your ability to use the core functionalities of creating and sharing digital invitations. However, by denying certain permissions, you may not be able to access all features of the App, such as sending SMS invitations or using the live telecast features with audio and video.</p>
            <p>2.3 You can manage your permissions at any time through your device settings.</p>

            <h2>3. User-Generated Content & Live Telecast</h2>
            <p>3.1 You retain all ownership rights to the content you create and share through the App (“User Content”). This includes invitation designs and information captured during the live telecast feature (“Live Content”). By using the live telecast feature, you grant us a non-exclusive, royalty-free license to transmit and distribute your Live Content to invited guests for the duration of the event. You can revoke this license at any time by deleting your User Content from the App or ending your live telecast.</p>
            <p>3.2 You are solely responsible for ensuring that your User Content and Live Content do not violate the rights of any third party. You warrant that you have all necessary permissions and licenses for any content captured during your live telecast, including for people depicted and any copyrighted materials.</p>
            <p>3.3 You agree not to live telecast any content that is:</p>
            <ul>
                <li>Illegal or promotes illegal activity.</li>
                <li>Violates the privacy rights of others, including capturing people without their consent.</li>
                <li>Contains personal information that you do not have the right to share, such as phone numbers, email addresses, or home addresses.</li>
                <li>Sexually explicit or offensive.</li>
                <li>Infringes on the intellectual property rights of others.</li>
            </ul>
            <p>3.4 We reserve the right to terminate your live telecast, suspend your account, or report you to the appropriate authorities if you violate these Terms.</p>

            <h2>4. Sharing with Third-Party Apps</h2>
            <p>4.1 The App may allow you to share invitations and other content with third-party applications. You are responsible for reviewing the terms and conditions of these third-party services.</p>
            <p>4.2 We are not responsible for the availability or functionality of any third-party services.</p>

            <h2>5. Third-Party Services & Integrations</h2>
            <p>5.1 The App may integrate with third-party services to provide features such as event management tools or photo editing tools. These services may have their own privacy policies and terms of service. We encourage you to review these policies before using any integrated services.</p>
            <p>5.2 We are not responsible for the privacy practices of any third-party services.</p>

            <h2>6. User Content & Reviews</h2>
            <p>6.1 You may submit reviews and ratings for products offered through the App ("Reviews"). By submitting a Review, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, publish, and translate such content in connection with the App and our marketing efforts. You represent and warrant that you own all rights to the Reviews you submit and that such Reviews do not violate any rights of any third party.</p>
            <p>6.2 You agree not to submit any Reviews that are:</p>
            <ul>
                <li>False, misleading, or deceptive.</li>
                <li>Illegal or promote illegal activity.</li>
                <li>Infringe on the intellectual property rights of others.</li>
                <li>Harassing, abusive, defamatory, obscene, or threatening.</li>
                <li>Off-topic or irrelevant to the product being reviewed.</li>
            </ul>
            <p>6.3 We reserve the right to remove any Reviews that violate these Terms or that we deem inappropriate for any reason.</p>

            <h2>7. Orders & Payment</h2>
            <p>7.1 All orders placed through the App are subject to availability and acceptance by us. We reserve the right to refuse or cancel any order without reason at any time before shipment.</p>
            <p>7.2 You agree to provide accurate and complete information for your orders, including your billing and shipping address.</p>
            <p>7.3 We accept various payment methods as displayed in the App. You are responsible for ensuring that the chosen payment method is valid and has sufficient funds to cover the order amount.</p>
            <p>7.4 All prices listed on the App are inclusive of Goods and Services Tax (GST) at the applicable rate in India.</p>
            <p>7.5 We strive to ensure the accuracy of all product information and pricing. However, errors may occur. We reserve the right to correct any errors in pricing or product information before or after your order is placed. In such a case, we will notify you and offer you the option to cancel your order or proceed at the corrected price.</p>
            <p>7.6 We will send you an order confirmation email/message once your order has been placed and accepted.</p>
            <p>7.7 We use a secure payment gateway to process all transactions. However, you acknowledge that internet transactions are inherently risky and we cannot guarantee the security of your information.</p>

            <h2>8. Delivery & Returns</h2>
            <p>8.1 Delivery times may vary depending on the location.</p>
            <p>8.2 Orders placed in the Gifts option will be shipped to the address provided during checkout.</p>
            <p>8.3 Orders placed during watching a live celebration, as a Guest will be delivered to the address provided by the Host at the time of creating the invitation.</p>
            <p>8.4 You are responsible for all applicable shipping charges associated with your order.</p>
            <p>8.5 We offer a return and exchange policy for products that are defective, damaged during shipping, or not as described. You can find the detailed return and exchange policy within the App or on our website (if applicable).</p>
            <p>8.6 To return or exchange a product, you must follow the instructions outlined in our return and exchange policy.</p>
            <p>8.7 Return shipping costs may be your responsibility, depending on the reason for the return.</p>
            <p>8.8 We will process your return or exchange request within a reasonable timeframe.</p>

            <h2>9. Intellectual Property</h2>
            <p>9.1 The App and all of its content, including logos, trademarks, and copyrights, are the property of Vinpact Digital Strategies Private Limited or its licensors. You may not use any of this intellectual property without our express written permission.</p>

            <h2>10. Disclaimers & Limitations of Liability</h2>
            <p>10.1 The App is provided "as is" and without warranties of any kind, express or implied. We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <p>10.2 We are not responsible for any damages arising from your use of the App, including, but not limited to, direct, indirect, incidental, consequential, or punitive damages.</p>

            <h2>11. Indemnification</h2>
            <p>11.1 You agree to indemnify and hold harmless Vinpact Digital Strategies Private Limited and its officers, directors, employees, agents, and licensors from and against any and all claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising from or relating to your use of the App or your violation of these Terms.</p>

            <h2>12. Governing Law & Dispute Resolution</h2>
            <p>12.1 These Terms will be governed by and construed in accordance with the laws of Hyderabad, India, without regard to its conflict of law provisions.</p>
            <p>12.2 Any dispute arising out of or relating to these Terms will be subject to the exclusive jurisdiction of the courts located in Hyderabad, India.</p>

            <h2>13. Updates to These Terms</h2>
            <p>13.1 We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the App.</p>
            <p>13.2 You are advised to review these Terms periodically for any changes. Your continued use of the App after the posting of any revised Terms constitutes your acceptance of such revised Terms.</p>
        </div>
    );
}

export default TermsAndConditions;
